import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from './UserContext';
import { localAPIPath, liveAPIPath } from './utils/constants';

const root = ReactDOM.createRoot(document.getElementById('root'));

let _user = JSON.parse(localStorage.getItem('userData'));
console.log(_user);
if(_user != null){
  fetch( liveAPIPath + `api/getcustomerprofile/${_user.customer_id}`)  // Replace with your API URL
  .then(response => response.json())
  .then(data => {
    localStorage.setItem('token', data?.token == undefined ? null : data?.token);
    localStorage.setItem('userData', JSON.stringify(data?.customer));
  })
  .catch(error => console.error('Error fetching data:', error));
}
else{
  localStorage.setItem('token', null);
  localStorage.setItem('userData', null);
}



root.render(
  <BrowserRouter>
    <UserProvider>
      <App />
    </UserProvider>
  </BrowserRouter>
);


