import { NavLink, useParams } from 'react-router-dom';
import { fetchSeriesAndModel } from '../../utils/api';
import { useEffect, useState } from 'react';
import './model.css';
import phone from '../../assets/images/sell-img.png';
import { modelImagePath } from '../../utils/constants';
import Loader from '../../components/loader/loader';

function Model() {
    const { slug } = useParams();
    const [brandData, setBrandData] = useState(null);
    const [seriesData, setSeriesData] = useState(null);
    const [modelData, setModelData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedSeries, setSelectedSeries] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchSeriesAndModel(slug);
                setSeriesData(response.series);
                setModelData(response.models);
                setBrandData(response.brand);
                setLoading(false);
            } catch (error) {
                // Handle error
                console.error('Error fetching sell mobile data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleCheckboxChange = (seriesSlug) => {
        if (selectedSeries.includes(seriesSlug)) {
            setSelectedSeries(selectedSeries.filter((slug) => slug !== seriesSlug));
        } else {
            setSelectedSeries([...selectedSeries, seriesSlug]);
        }
    };

    const filteredModels = selectedSeries.length > 0
        ? modelData.filter((model) => selectedSeries.includes(parseInt(model.CategoryCode)))
        : modelData;

    const filteredModelsBySearch = searchQuery
        ? filteredModels.filter((model) => model.ItemName.toLowerCase().includes(searchQuery.toLowerCase()))
        : filteredModels;

    return (
        <>
            {loading && <Loader />}
            <section className="Search-section section-padding sell-result-Desktop" id="section_1" style={{ paddingTop: "0px" }}>
                <div className="container">
                    <div className='col-md-12 mb-4 mt-4'>
                        <h4>Trade In Your Used iPhone for Cash</h4>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><NavLink to="/">Home  <i className="bi bi-chevron-right me-1"></i></NavLink></li>
                                    <li className="breadcrumb-item"><NavLink to="/sell-mobile">Sell Old Mobile Phone <i className="bi bi-chevron-right me-1"></i></NavLink></li>
                                    <li className="breadcrumb-item active" aria-current="page">{brandData?.CategoryGroupName}</li>
                                </ol>
                            </nav>
                        </div>
                        <div className='col-md-6'>
                            <form action="#" method="get" className="custom-form search-form flex-fill me-0" role="search" style={{ maxWidth: "100%" }}>
                                <div className="input-group input-group-lg">
                                    <input name="search" type="search" className="form-control" id="search" placeholder="Search For..."
                                        aria-label="Search" value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)} />

                                    <button type="submit" className="form-control" id="submit">
                                        <i className="bi-search"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </section>

            <section className="Search-section section-padding sell-result-Mobile" id="section_1">
                <div className="container">
                    <div className='col-md-12 mb-4 mt-4'>
                        <h4>Trade In Your Used iPhone for Cash</h4>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><NavLink to="/">Home  <i className="bi bi-chevron-right me-1"></i></NavLink></li>
                                    <li className="breadcrumb-item"><NavLink to="/sell-mobile">Sell Old Mobile Phone <i className="bi bi-chevron-right me-1"></i></NavLink></li>
                                    <li className="breadcrumb-item active" aria-current="page">{brandData?.CategoryGroupName}</li>
                                </ol>
                            </nav>
                        </div>
                        <div className='col-md-6'>
                            <form className="custom-form search-form flex-fill me-0" role="search" style={{ maxWidth: "100%" }}>
                                <div className="input-group input-group-lg">
                                    <input name="search" type="search" className="form-control" id="search" placeholder="Search For..."
                                        aria-label="Search" value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)} />

                                    <button type="submit" className="form-control" id="submit">
                                        <i className="bi-search"></i>
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>

                </div>
            </section>

            <section className="about-section section-padding" id="section_2" style={{ paddingTop: "0px" }}>

                <div className="container">
                    <div className="col-lg-12 col-12">
                        <div className="section-title-wrap mb-5">
                            <h4 className="section-title">Pick Model</h4>
                        </div>
                    </div>
                    <div className='sell-result-Desktop'>

                        <div className="row">
                            <div className='col-md-8'>
                                <div className='row'>
                                    {filteredModelsBySearch?.map((item) => (
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-5" key={item.ItemCode}>
                                            <div className="team-thumb bg-white border-lg">
                                                <NavLink to={`/sell-mobile/${item.cgc_slug}/${item.ic_slug}`} style={{ width: "100%" }}>
                                                    <img src={modelImagePath + item.ic_slug + '.webp'} alt={item.model}

                                                        className="brand-image img-fluid"
                                                        id={item.image}
                                                    />
                                                    <p className='itemfont text-center'><b>{item.ItemName}</b></p>
                                                </NavLink>
                                            </div>
                                        </div>
                                    ))}

                                    {filteredModelsBySearch?.length == 0 && <h1>No record found.</h1>}

                                </div>
                            </div>
                            <div className='col-md-4'>

                                <div className="check-group">
                                    <h6 className='check-group__title'>{brandData?.CategoryGroupName}</h6>
                                    {seriesData?.map((series) => (
                                        <label htmlFor={series?.cc_slug} className="checkbox" key={series?.cc_slug}>
                                            <input
                                                className="checkbox__input"
                                                type="checkbox"
                                                id={series?.cc_slug}
                                                value={series.cc_slug}
                                                checked={selectedSeries.includes(series.CategoryCode)}
                                                onChange={() => handleCheckboxChange(series.CategoryCode)}
                                            />
                                            <svg className="checkbox__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                                                <rect width="21" height="21" x=".5" y=".5" fill="#FFF" stroke="#006F94" rx="3" />
                                                <path className="tick" stroke="#6EA340" fill="none" strokeLinecap="round" strokeWidth="4" d="M4 10l5 5 9-9" />
                                            </svg>
                                            <span className="checkbox__label">{series?.CategoryName}</span>
                                        </label>
                                    ))}

                                </div>
                            </div>

                        </div>

                    </div>
                    <div className='sell-result-Mobile' >
                        <div className="mobile-custom-row" style={{ paddingTop: "0px" }}>
                            <div className='col-12 mb-4'>

                                {/* <div className="check-group">
                                    <h6 className='check-group__title'>{brandData?.CategoryGroupName} <i className='bi bi-chevron-down right'></i></h6>

                                    {seriesData?.map((series) => (
                                        <label htmlFor={series?.cc_slug} className="checkbox" key={series?.cc_slug}>
                                            <input
                                                className="checkbox__input"
                                                type="checkbox"
                                                id={series?.cc_slug}
                                                value={series.cc_slug}
                                                checked={selectedSeries.includes(series.CategoryCode)}
                                                onChange={() => handleCheckboxChange(series.CategoryCode)}
                                            />
                                            <svg className="checkbox__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                                                <rect width="21" height="21" x=".5" y=".5" fill="#FFF" stroke="#006F94" rx="3" />
                                                <path className="tick" stroke="#6EA340" fill="none" strokeLinecap="round" strokeWidth="4" d="M4 10l5 5 9-9" />
                                            </svg>
                                            <span className="checkbox__label itemfont">{series?.CategoryName}</span>
                                        </label>
                                    ))}

                                </div> */}
                                <div className="check-group">
                                    <h6 className='check-group__title' onClick={toggleAccordion} style={{ cursor: 'pointer' }}>
                                        {brandData?.CategoryGroupName}
                                        <i className={`bi bi-chevron-${isOpen ? 'up' : 'down'} float-right`}></i>
                                    </h6>

                                    {isOpen && (
                                        <div>
                                            {seriesData?.map((series) => (
                                                <label htmlFor={series?.cc_slug} className="checkbox mb-2" key={series?.cc_slug} >
                                                    <input
                                                        className="checkbox__input"
                                                        type="checkbox"
                                                        id={series?.cc_slug}
                                                        value={series.cc_slug}
                                                        checked={selectedSeries.includes(series.CategoryCode)}
                                                        onChange={() => handleCheckboxChange(series.CategoryCode)}
                                                    />
                                                    <svg className="checkbox__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                                                        <rect width="21" height="21" x=".5" y=".5" fill="#FFF" stroke="#006F94" rx="3" />
                                                        <path className="tick" stroke="#6EA340" fill="none" strokeLinecap="round" strokeWidth="4" d="M4 10l5 5 9-9" />
                                                    </svg>
                                                    <span className="checkbox__label itemfont">{series?.CategoryName}</span>
                                                </label>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className='row'>
                                    {filteredModelsBySearch?.map((item) => (
                                        <div className="col-lg-4 col-md-4 col-6 mb-5" key={item.ItemCode}>
                                            <div className="team-thumb bg-white border-lg">
                                                <NavLink to={`/sell-mobile/${item.cgc_slug}/${item.ic_slug}`} style={{ width: "100%" }}>
                                                    <img src={modelImagePath + item.ic_slug + '.webp'} alt={item.model}

                                                        className="brand-image img-fluid"
                                                        id={item.image} style={{ height: "100px" }}
                                                    />
                                                    <p className='itemfont text-center'><b>{item.ItemName}</b></p>
                                                </NavLink>
                                            </div>
                                        </div>
                                    ))}

                                    {filteredModelsBySearch?.length == 0 && <h1>No record found.</h1>}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Model;
