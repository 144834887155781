import React, { useEffect, useState } from 'react';
import './loginModal.css';
import OtpInput from 'react-otp-input';
import { customerLogin, customerOtpVerify, customerRegistration, fetchExistsCustomer, forgetpasswordcustomer, forgetpasswordverifycustomerotp, resendotpOTPRequest, sendForgetPasswordOTPRequest } from '../../utils/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUser } from '../../UserContext';
import toast from 'react-hot-toast';


const LoginModal = ({ isOpen, onClose, selectedModel = null }) => {
    const [mobileNo, setMobileNo] = useState('');
    const [name, setName] = useState('');
    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordSighin, setpasswordSighin] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [step, setStep] = useState(1);
    const [customerId, setCustomerId] = useState('');
    const [token, setToken] = useState(null);
    const { user, logoutUser, loginUser } = useUser();
    const [respMessage, setRespMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        // Initialize form data
        firstName: '',
        lastName: '',
        email: '',
    });


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const nextStep = () => {
        setStep(step + 1);
    };
    const prevStep = () => {
        setStep(step - 1);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission
        console.log('Form submitted:', formData);
    };
    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    //formik form handle submit
    const getStartedFormik = useFormik({
        initialValues: {
            mobileNo: mobileNo
        },
        validationSchema: Yup.object({
            mobileNo: Yup.string()
                .required('Mobile number is required')
                .matches(/^\d{10}$/, 'Mobile number must be 10 digits')
        }),
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                setMobileNo(values.mobileNo)
                const response = await fetchExistsCustomer(values.mobileNo);
                if (response?.success) {
                    if (response?.status === 'verified') {
                        getStartedFormik.resetForm();
                        setStep(2);
                    } else {
                        setStep(4);
                    }

                } else {
                    setStep(3);
                }
            } catch (error) {
                console.error('API error:', error);
            }
            setSubmitting(false);
        }
    });

    //formik form handle login
    const loginFormik = useFormik({
        initialValues: {
            password: password
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .required('Password is required')
        }),
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            const platform = 'web';
            try {
                const response = await customerLogin(mobileNo, values.password, platform);
                if (response?.success) {
                    loginUser(response?.customer, response?.token)
                    loginFormik.resetForm();
                    setStep(1);
                    onClose();
                } else {
                    toast.error(response?.message);
                    setRespMessage(response?.message)
                }
            } catch (error) {
                console.error('API error:', error);
            }
            setSubmitting(false);
        }
    });

    //formik form handle login
    const verifyOtpFormik = useFormik({
        initialValues: {
            otp: ''
        },
        validationSchema: Yup.object({
            otp: Yup.string().required('Otp is required'),
        }),
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                const response = await customerOtpVerify(mobileNo, values.otp);
                if (response?.success) {
                    loginUser(response?.customer, response?.token);
                    setMobileNo(null);
                    setStep(1);
                    setOtp(null);
                    verifyOtpFormik.resetForm();
                    onClose();
                } else {
                    toast.error(response?.message);
                }
            } catch (error) {
                console.error('API error:', error);
            }
            setSubmitting(false);
        }
    });

    const verifyForgetOtpFormik = useFormik({
        initialValues: {
            otp: ''
        },
        validationSchema: Yup.object({
            otp: Yup.string().required('Otp is required'),
        }),
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                const response = await forgetpasswordverifycustomerotp(mobileNo, values.otp);
                if (response?.success) {
                    setCustomerId(response?.customer?.customer_id);
                    verifyForgetOtpFormik.resetForm();
                    setStep(6);
                } else {
                    toast.error(response?.message);
                }
            } catch (error) {
                console.error('API error:', error);
            }
            setSubmitting(false);
        }
    });

    //formik form handle login
    const registerFormik = useFormik({
        initialValues: {
            name: name,
            password: password
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            password: Yup.string()
                .required('Password is required')
                .min(8, 'Password must be at least 8 characters')
            //.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/, 'Password must contain at least one uppercase letter, one lowercase letter, and one number')
        }),
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            const platform = 'web';
            try {
                const response = await customerRegistration(mobileNo, values.name, values.password, platform);
                if (response?.success) {
                    registerFormik.resetForm();
                    setMobileNo(response?.mobile);
                    setStep(4);
                } else {
                    toast.error(response?.message);
                }
            } catch (error) {
                console.error('API error:', error);
            }
            setSubmitting(false);
        }
    });

    const forgetPasswordFormik = useFormik({
        initialValues: {
            newPassword: newPassword,
            confirmPassword: confirmPassword
        },
        validationSchema: Yup.object({
            newPassword: Yup.string()
                .required('Password is required')
                .min(8, 'Password must be at least 8 characters'),
            //.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/, 'Password must contain at least one uppercase letter, one lowercase letter, and one number')
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
                .required('Confirm Password is required'),
        }),
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            const platform = 'web';
            try {
                const response = await forgetpasswordcustomer(values.newPassword, customerId, platform);
                if (response?.success) {
                    loginUser(response?.customer, response?.token)
                    forgetPasswordFormik.resetForm();
                    setStep(1);
                    onClose();
                } else {
                    toast.error(response?.message);
                }
            } catch (error) {
                console.error('API error:', error);
            }
            setSubmitting(false);
        }
    });

    const forgetpasswordotp = async () => {
        setLoading(true);
        try {
            const response = await sendForgetPasswordOTPRequest(mobileNo);
            if (response?.success) {

            } else {

            }
            setLoading(false);
        } catch (error) {
            console.error('API error:', error);
            setLoading(false);
        }
    };

    const resendotpOTP = async () => {
        setLoading(true);
        try {
            const response = await resendotpOTPRequest(mobileNo);
            if (response?.success) {

            } else {

            }
            setLoading(false);
        } catch (error) {
            console.error('API error:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (step == 1) {
            setMobileNo('');
        }
        if (step == 5) {
            forgetpasswordotp();
        }
    }, [step]);

    return (
        <div className={`modal ${isOpen ? 'is-active' : ''}`}>
            <div className="modal-background"></div>

            <div className="modal-content">
                <button className="modal-close is-large" aria-label="close" onClick={() => { setStep(1); onClose() }}>
                    <i className="bi bi-x-circle close-icon"></i>

                </button>

                <div className='row'>
                    <div className='col-lg-6 col-md-12  col-sm-12 ' >

                        {step === 1 && (
                            <form onSubmit={getStartedFormik.handleSubmit} className="custom-form contact-form" role="form">
                                <div className="box">
                                    <div style={{ marginBottom: '30px' }}>
                                        <h4 className="title">Get started</h4>
                                        <p className="mb-40">Enter your mobile number to login or signup</p>
                                    </div>

                                    {selectedModel && (
                                        <div className="row mb-4">
                                            <div className="col-12 col-md-3 text-center mb-3 mb-md-0">
                                                <img src={selectedModel?.item_image} alt="img" className="img-fluid" style={{ height: "100px" }} />
                                            </div>
                                            <div className="col-12 col-md-9">
                                                <p className="font-weight-bold">{selectedModel?.model_name} {selectedModel?.variant_name}</p>
                                                <h3 style={{ color: "#7e57c2" }}>
                                                    <span><i className="bi bi-currency-rupee"></i>XX,XXX</span>
                                                </h3>
                                            </div>
                                        </div>
                                    )}

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="mobileNo"
                                                    placeholder="Mobile No"
                                                    required
                                                    onChange={getStartedFormik.handleChange}
                                                    onBlur={getStartedFormik.handleBlur}
                                                    value={getStartedFormik.values.mobileNo}
                                                />
                                                <label htmlFor="floatingInput">Mobile No</label>
                                                <span className="error-message">
                                                    {getStartedFormik.touched.mobileNo && getStartedFormik.errors.mobileNo && (
                                                        <div className="error">{getStartedFormik.errors.mobileNo}</div>
                                                    )}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-12 mx-auto mt-3">
                                            <button type="submit" className="form-control" disabled={getStartedFormik.isSubmitting}>Proceed</button>
                                        </div>

                                    </div>
                                </div>
                            </form>

                        )}
                        {step === 2 && (
                            <form onSubmit={loginFormik.handleSubmit} className="custom-form contact-form">
                                <div className="box">
                                    <div style={{ marginBottom: '30px' }}>
                                        <h4 className="title"> Sign In </h4>
                                        <p className='mb-40'>Enter your mobile number to login or signup</p>
                                        <p className='text-danger'>{respMessage}</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="form-floating ">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name='mobile'
                                                    placeholder="Mobile No"
                                                    required=""
                                                    disabled
                                                    value={mobileNo}
                                                />
                                                <label htmlFor="floatingInput">Mobile No</label>
                                            </div>
                                            <p className='text-right mb-0'><button type="button" className='button-like-link' onClick={() => { setStep(1); }}>Change Mobile</button></p>

                                            <div className="form-floating mt-3">
                                                <input
                                                    className="form-control"
                                                    type="password"
                                                    name='password'
                                                    placeholder="Password"
                                                    onChange={loginFormik.handleChange}
                                                    onBlur={loginFormik.handleBlur}
                                                    value={loginFormik.values.password}
                                                />
                                                <label htmlFor="floatingInput">Password</label>
                                                <span className="error-message">
                                                    {loginFormik.touched.password && loginFormik.errors.password ? (
                                                        <div className="error">{loginFormik.errors.password}</div>
                                                    ) : null}
                                                </span>
                                            </div>
                                        </div>
                                        <p className='text-right'><button type="button" className='button-like-link' onClick={() => { setStep(5); }}>Forgot Password?</button></p>
                                        <div className="col-lg-6 col-12 m-auto mt-3">
                                            <button type="submit" className="form-control" disabled={loginFormik.isSubmitting}>Sign In</button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        )}
                        {step === 3 && (
                            <form onSubmit={registerFormik.handleSubmit} className="custom-form contact-form">
                                <div className="box">
                                    <div style={{ marginBottom: '70px' }}>
                                        <h4 className="title"> Signup
                                        </h4>
                                        <p className='mb-40'>Enter your mobile number to login or signup
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="form-floating">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name='mobileNo'
                                                    placeholder="Mobile No"
                                                    required=""
                                                    disabled
                                                    value={mobileNo}
                                                />
                                                <label htmlFor="floatingInput">Mobile No</label>
                                            </div>
                                            <div className="form-floating  mt-4">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name='name'
                                                    placeholder="Name"
                                                    onChange={registerFormik.handleChange}
                                                    onBlur={registerFormik.handleBlur}
                                                    value={registerFormik.values.name}
                                                />
                                                <label htmlFor="floatingInput">Name</label>
                                                <span className="error-message">
                                                    {registerFormik.touched.name && registerFormik.errors.name ? (
                                                        <div className="error">{registerFormik.errors.name}</div>
                                                    ) : null}
                                                </span>
                                            </div>
                                            <div className="form-floating  mt-4">
                                                <input
                                                    className="form-control"
                                                    type="password"
                                                    name='password'
                                                    placeholder="Password"
                                                    onChange={registerFormik.handleChange}
                                                    onBlur={registerFormik.handleBlur}
                                                    value={registerFormik.values.password}
                                                />
                                                <label htmlFor="floatingInput">Password</label>
                                                <span className="error-message">
                                                    {registerFormik.touched.password && registerFormik.errors.password ? (
                                                        <div className="error">{registerFormik.errors.password}</div>
                                                    ) : null}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12 m-auto mt-5">
                                            <button type="submit" className="form-control" >Proceed</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                        {step === 4 && (
                            <form onSubmit={verifyOtpFormik.handleSubmit} className="custom-form contact-form">
                                <div className="box">
                                    <div style={{ marginBottom: '70px' }}>
                                        <h4 className="title"> Enter OTP
                                        </h4>
                                        <p className='mb-40'>A one time password has been sent to {mobileNo} Change</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-12 opt">
                                            <OtpInput className="inputStyle" style={{ width: "3em !important" }}
                                                numInputs={4}
                                                name='otp'
                                                renderSeparator={<span>-</span>}
                                                renderInput={(props) => <input {...props} />}
                                                value={verifyOtpFormik.values.otp}
                                                onChange={otp => verifyOtpFormik.setFieldValue('otp', otp)}
                                                onBlur={verifyOtpFormik.handleBlur('otp')}
                                            />
                                            <span className="error-message">
                                                {verifyOtpFormik.touched.otp && verifyOtpFormik.errors.otp ? (
                                                    <div className="error">{verifyOtpFormik.errors.otp}</div>
                                                ) : null}
                                            </span>
                                            <div className="form-floating  mt-5">
                                                <p className="">Didn't receive the OTP yet? <button className='button-like-link' onClick={() => { resendotpOTP(); }}>Resend</button></p>
                                            </div>

                                        </div>
                                        <div className="col-lg-6 col-12 m-auto mt-5">
                                            <button type="submit" className="form-control" >Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                        {step === 5 && (
                            <form onSubmit={verifyForgetOtpFormik.handleSubmit} className="custom-form contact-form">
                                <div className="box">
                                    <div style={{ marginBottom: '70px' }}>
                                        <h4 className="title"> Enter OTP
                                        </h4>
                                        <p className='mb-40'>A one time password has been sent to {mobileNo} Change</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-12 opt">
                                            <OtpInput className="inputStyle" style={{ width: "3em !important" }}
                                                numInputs={4}
                                                name='otp'
                                                renderSeparator={<span>-</span>}
                                                renderInput={(props) => <input {...props} />}
                                                value={verifyForgetOtpFormik.values.otp}
                                                onChange={otp => verifyForgetOtpFormik.setFieldValue('otp', otp)}
                                                onBlur={verifyForgetOtpFormik.handleBlur('otp')}
                                            />
                                            <span className="error-message">
                                                {verifyForgetOtpFormik.touched.otp && verifyForgetOtpFormik.errors.otp ? (
                                                    <div className="error">{verifyForgetOtpFormik.errors.otp}</div>
                                                ) : null}
                                            </span>
                                            <div className="form-floating  mt-5">
                                                <p className="">Didn't receive the OTP yet? <button type="button" className='button-like-link' onClick={() => { resendotpOTP(); }}>Resend</button></p>
                                            </div>

                                        </div>
                                        <div className="col-lg-6 col-12 m-auto mt-5">
                                            <button type="submit" className="form-control" >Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                        {step === 6 && (
                            <form onSubmit={forgetPasswordFormik.handleSubmit} className="custom-form contact-form">
                                <div className="box">
                                    <div style={{ marginBottom: '70px' }}>
                                        <h4 className="title"> Forgot Password
                                        </h4>
                                        <p className='mb-40'>Enter your new password
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="form-floating">
                                                <input
                                                    className="form-control"
                                                    type="password"
                                                    name='newPassword'
                                                    placeholder="New Password"
                                                    onChange={forgetPasswordFormik.handleChange}
                                                    onBlur={forgetPasswordFormik.handleBlur}
                                                    value={forgetPasswordFormik.values.newPassword}
                                                />
                                                <label htmlFor="floatingInput">New Password</label>
                                                <span className="error-message">
                                                    {forgetPasswordFormik.touched.newPassword && forgetPasswordFormik.errors.newPassword ? (
                                                        <div className="error">{forgetPasswordFormik.errors.newPassword}</div>
                                                    ) : null}
                                                </span>
                                            </div>
                                            <div className="form-floating  mt-4">
                                                <input
                                                    className="form-control"
                                                    type="password"
                                                    name='confirmPassword'
                                                    placeholder="New Password"
                                                    onChange={forgetPasswordFormik.handleChange}
                                                    onBlur={forgetPasswordFormik.handleBlur}
                                                    value={forgetPasswordFormik.values.confirmPassword}
                                                />
                                                <label htmlFor="floatingInput">Confirm Password</label>
                                                <span className="error-message">
                                                    {forgetPasswordFormik.touched.confirmPassword && forgetPasswordFormik.errors.confirmPassword ? (
                                                        <div className="error">{forgetPasswordFormik.errors.confirmPassword}</div>
                                                    ) : null}
                                                </span>
                                            </div>

                                        </div>
                                        <div className="col-lg-6 col-12 m-auto mt-5">
                                            <button type="submit" className="form-control" >Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    </div>
                    <div className='col-md-6 d-none d-lg-block nonImg' style={{ backgroundColor: "#7e57c2" }}>
                        <div className="overlay-container">
                            <div className="overlay">
                                <div className="overlay-panel overlay-right">
                                    <img src='/images/login.png' style={{ width: "100%" }} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default LoginModal;
