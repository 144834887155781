import { NavLink, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './modelDetail.css';
import { fetchMobileVariants } from '../../utils/api';
import { modelImagePath } from '../../utils/constants';
import Loader from '../../components/loader/loader';

function MobileDetail() {
    const { brand, model } = useParams();
    const [variantData, setVariantData] = useState(null);
    const [brandData, setBrandData] = useState(null);
    const [modelData, setModelData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedVariant, setSelectedVariant] = useState(null);
    const [finalValue, setFinalValue] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchMobileVariants(model);
                setVariantData(response?.variants);
                if (response?.variants.length == 1) {
                    setSelectedVariant(response?.variants[0]);
                    const formattedAmount = new Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: 'INR'
                    }).format(response?.variants[0]?.Rate);
                    setFinalValue(formattedAmount);
                }
                setModelData(response?.model);
                setBrandData(response?.brand);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching sell mobile data:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    function handleVariant(obj) {
        setSelectedVariant(obj);
        const formattedAmount = new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(obj?.Rate);
        setFinalValue(formattedAmount);
    }

    function handleRedirection() {
        navigate(`/options/${model}/${selectedVariant?.VariantName.toLowerCase()}`);
    }

    return (
        <div>
            {loading && <Loader />}
            <section className="Search-section section-padding sell-result-Desktop" id="section_1" style={{ paddingTop: "0px" }}>
                <div className="container">
                    <div className='col-md-12 mb-4 mt-4'>
                        <h4>Trade Your Pre-Owned {modelData?.ItemName} for Cash</h4>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><NavLink to="/">Home  <i className="bi bi-chevron-right me-1"></i></NavLink></li>
                                    <li className="breadcrumb-item"><NavLink to="/sell-mobile">Sell Old Mobile Phone <i className="bi bi-chevron-right me-1"></i></NavLink></li>
                                    <li className="breadcrumb-item"><NavLink to={`/sell-mobile/${brandData?.slug}`} >Sell Old {brandData?.CategoryGroupName} <i className="bi bi-chevron-right me-1"></i></NavLink></li>

                                    <li className="breadcrumb-item active" aria-current="page">{modelData?.ItemName}</li>
                                </ol>
                            </nav>
                        </div>

                    </div>

                </div>
            </section>

            <section className="Search-section section-padding sell-result-Mobile" id="section_1">
                <div className="container">
                    <div className='col-md-12 mb-4 mt-4'>
                        <h4>Trade In Your Used iPhone for Cash</h4>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><NavLink to="/">Home  <i className="bi bi-chevron-right me-1"></i></NavLink></li>
                                    <li className="breadcrumb-item"><NavLink to="/sell-mobile">Sell Old Mobile Phone <i className="bi bi-chevron-right me-1"></i></NavLink></li>
                                    <li className="breadcrumb-item"><NavLink to={`/sell-mobile/${brandData?.slug}`} >Sell Old {brandData?.CategoryGroupName} <i className="bi bi-chevron-right me-1"></i></NavLink></li>

                                    <li className="breadcrumb-item active" aria-current="page">{modelData?.ItemName}</li>
                                </ol>
                            </nav>
                        </div>
                        {/* <div className='col-md-6'>
                            <form action="#" method="get" className="custom-form search-form flex-fill me-0" role="search" style={{ maxWidth: "100%" }}>
                                <div className="input-group input-group-lg">
                                    <input name="search" type="search" className="form-control" id="search" placeholder="Search For..."
                                        aria-label="Search" />

                                    <button type="submit" className="form-control" id="submit">
                                        <i className="bi-search"></i>
                                    </button>
                                </div>
                            </form>
                        </div> */}
                    </div>

                </div>
            </section>
            <section className="about-section section-padding" id="section_2" style={{ paddingTop: "0px" }}>

                <div className="container">
                    <div className='row justify-content-center'>
                        <div className="col-md-10">
                            <div className="custom-block1">
                                <h6 className="mb-2">{modelData?.ItemName}</h6>
                                <div className='row'>
                                    <div className="col-md-4 d-flex align-items-center justify-content-center" >
                                        <div className="custom-block-icon-wrap">

                                            <div className="custom-block-image-wrap text-center">
                                                <img src={modelImagePath + modelData?.ic_slug + '.webp'} alt="img" style={{ width: "auto", height: "160px", margin: "0 auto" }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="custom-block-info col-md-8">


                                        <h6 className='pt-2'>Choose a Variant</h6>
                                        <div className="main-container">
                                            <div className="radio-buttons">

                                                {variantData?.length > 0 && variantData?.length == 1 ?
                                                    (
                                                        <label className="custom-radio">
                                                            <input type="radio" name="radio" checked />
                                                            <span className="radio-btn">
                                                                <div className="hobbies-icon">
                                                                    <h3 className="">{variantData[0]?.VariantName}</h3>
                                                                </div>
                                                            </span>
                                                        </label>

                                                    ) : (variantData?.map((item) => (
                                                        <label className="custom-radio" onClick={() => handleVariant(item)} key={item?.id}>
                                                            <input type="radio" name="radio" />
                                                            <span className="radio-btn">
                                                                <div className="hobbies-icon">
                                                                    <h3 className="" >{item?.VariantName}</h3>
                                                                </div>
                                                            </span>
                                                        </label>
                                                    ))
                                                    )
                                                }


                                            </div>
                                        </div>
                                        <p className='mb-2 mt-3 text-center-sm'>
                                            <b>Get Upto</b>

                                        </p>
                                        <div className="mt-3 text-center-sm">
                                            <h3 className='' style={{ color: "#7e57c2" }}>
                                                <span>{finalValue}</span>
                                            </h3>
                                        </div>
                                        <div className="mt-3 text-center-sm">
                                            <button className="btn custom-btn" disabled={selectedVariant == null} onClick={handleRedirection}>
                                                Get Precise Assessment
                                            </button>
                                        </div>
                                        <p className='mb-2 phoneBoxText'>
                                            The price mentioned is subject to change based on the condition of the items and is not definitive. The final offer will be provided upon completion of the assessment.
                                        </p>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

            </section>
        </div>
    );
}

export default MobileDetail;