import React, { useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { getsellnowoder, cancelorderbycustomer } from '../../utils/api';
import { useEffect } from 'react';
import './salesorderdetail.css';
import CardPaymentImg from '../../assets/images/icon/card-payment.png';
import PickUpImg from '../../assets/images/icon/pickup.png';
import RequestImg from '../../assets/images/icon/request-for-proposal.png';
import RecommendationImg from '../../assets/images/icon/recommendation.png';
import DiscountImg from '../../assets/images/icon/discount.png';
import AcceptedImg from '../../assets/images/icon/thumbsup.svg';
import PendingPymentImg from '../../assets/images/icon/pendingPyment.svg';
import SuccessImg from '../../assets/images/icon/success.svg';
import statusSuccessIcon from '../../assets/images/icon/done.png';
import statuscancelledIcon from '../../assets/images/icon/close.png';
import MobileImg from '../../assets/images/icon/mobile.svg';
import CloseImg from '../../assets/images/icon/cancel.svg';
import toast from 'react-hot-toast';
import { modelImagePath } from '../../utils/constants';
import moment from 'moment';
import Loader from '../../components/loader/loader';
import DeviceReport from '../../components/device-report/DeviceReport';
import OrderCancelModal from '../../components/order-cancel-model/ordercancelModal';

const SalesOrdersDetail = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { ref_no } = useParams();
    const [enquiryResponse, setEnquiryResponse] = useState(null);
    const [status, setStatus] = useState('');
    const [isOpen2, setIsOpen2] = useState(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const toggleAccordion2 = () => {
        setIsOpen2(!isOpen2);
    };
    const fetchData = async () => {
        try {
            const response = await getsellnowoder(ref_no);
            if (response?.success) {
                setEnquiryResponse(response?.enquiry);
                setStatus(response?.enquiry?.Status);
            } else {
                toast.error(response?.message);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching sell mobile data:', error);
        } finally {
            setLoading(false);
        }
    };

    const openCancelModal = () => {
        setIsCancelModalOpen(true);
    };

    const closeCancelModal = () => {
        fetchData();
        setIsCancelModalOpen(false);
    };

    useEffect(() => {
        fetchData();
    }, [status]);

    return (
        <>
            {loading && <Loader />}
            {isCancelModalOpen && <OrderCancelModal isCancelOpen={isCancelModalOpen} OnCancelClose={closeCancelModal} ref_no={ref_no} />}
            <section className="about-section section-padding" id="section_1" style={{ paddingBottom: "10px" }}>
                <div className="container ">
                    <div className='row justify-content-center'>
                        <div className='col-md-10'>
                            <div className='col-md-10 mb-4 mt-4'>
                                <h4 className='mt-4'>Trade In Your Used iPhone for Cash</h4>
                            </div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <NavLink to="/" className="">
                                            Home  <i className="bi bi-chevron-right me-1"></i>
                                        </NavLink>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <NavLink to="/sales-orders" className="">
                                            Sales  <i className="bi bi-chevron-right me-1"></i>
                                        </NavLink>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Sales Order Detail</li>
                                </ol>
                            </nav>
                        </div>

                    </div>

                </div>
            </section>
            <section className="about-section section-padding" id="section_2" style={{ paddingTop: "20px" }}>

                <div className="container">
                    <div className='row justify-content-center'>
                        <div className="col-md-10">

                            <div className="section-title-wrap mb-3">
                                <h4 className="section-title">Orders Detail </h4>
                            </div>
                            <div className="custom-block" style={{ marginBottom: "10px", padding: "20px" }} >
                                <div className='row' style={{ borderBottom: "1px solid #ccc" }}>
                                    <div className="col-md-12">
                                        <div className='row'>
                                            <div className="col-10 mb-3">

                                                <p className='mb-0'>
                                                    <span className='mr-5'>{enquiryResponse?.Status == 'Accepted' && <img className='status-icon' src={AcceptedImg} />}
                                                        {enquiryResponse?.Status == 'Enquiry' && <img className='status-icon' src={PendingPymentImg} />}
                                                        {enquiryResponse?.Status == 'Cancelled' && <img className='status-icon' src={CloseImg} />}
                                                        {enquiryResponse?.Status == 'Out for Pick up' && <img className='status-icon' src={MobileImg} />}
                                                        {enquiryResponse?.Status == 'Completed' && <img className='status-icon' src={SuccessImg} />}</span>
                                                    <b>{enquiryResponse?.Status}</b>

                                                </p>
                                            </div>
                                            <div className="col-2 text-right"><span className="badge">Sell</span></div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-md-3" >
                                        <div className="custom-block-icon-wrap" style={{ marginTop: "10%" }}>
                                            <div className="custom-block-image-wrap text-center" style={{ backgroundColor: "var(--section-bg-color)", padding: "10px" }}>
                                                <img src={modelImagePath + enquiryResponse?.ic_slug + '.webp'} alt="img" style={{ width: "auto", height: "100px", margin: "0 auto" }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="custom-block-info col-md-9">
                                        <div className='row'>
                                            <div className='col-md-8'>
                                                <p className='mt-4 mb-0'>{moment(enquiryResponse?.TransactionDate).format("MMMM Do, YYYY, h:mm A")}</p>
                                                <p className='mt-0 mb-0'>{enquiryResponse?.Model}</p>
                                                <p className='mb-2 mt-0'>{enquiryResponse?.VariantInfo.toUpperCase()}</p>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className="mt-4 ">
                                                    <p className='' >
                                                        <b>
                                                            <span>Sell Amount</span>
                                                            <span style={{ color: "#7e57c2" }}> {new Intl.NumberFormat('en-IN', {
                                                                style: 'currency',
                                                                currency: 'INR'
                                                            }).format(enquiryResponse?.ExactValue)}</span>
                                                        </b>

                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <p className='mb-0'><b>Pickup Address</b></p>
                                                {enquiryResponse?.Address && <p className='mb-0'>{enquiryResponse?.Address}
                                                    {enquiryResponse?.landmark && <span>, {enquiryResponse?.landmark}</span>}</p>}
                                                <p>{enquiryResponse?.locality}, {enquiryResponse?.city}, {enquiryResponse?.state}, {enquiryResponse?.ZipCode}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="custom-block1" style={{ marginBottom: "10px", padding: "12px" }} >
                                <div className='row' style={{ borderBottom: "1px solid #ccc" }}>
                                    <div className="col-md-12">
                                        <div className='row my-3'>
                                            <div className="col-md-10">
                                                <h4 className=''>Sales Order Tracking
                                                </h4>
                                            </div>
                                            {enquiryResponse?.Status == 'Enquiry' && <div className="col-md-2 text-right">
                                                <button className='btn btn-danger btn-sm' onClick={openCancelModal} disabled={status === 'Cancelled'}>Order Cancel</button>
                                            </div>}
                                        </div>

                                    </div>

                                </div>
                                <div className='row'>

                                    <div className="custom-block-info col-md-12">
                                        <section id="status-timeline" className="status-container">
                                            <div className="status-timeline-block ">
                                                <div className="status-timeline-img status-picture " >
                                                    <img src={statusSuccessIcon} alt="SuccessIcon" />
                                                </div>

                                                <div className="status-timeline-content custom-block1">
                                                    <h6>Sales Enquiry </h6>
                                                    <p className='mb-0'>{moment(enquiryResponse?.TransactionDate).format("MMMM Do, YYYY, h:mm A")}</p>
                                                </div>
                                            </div>

                                            {enquiryResponse?.cancel_by_customer > 0 && enquiryResponse?.Status == 'Cancelled' && <div className="status-timeline-block">
                                                <div className="status-timeline-img status-movie">
                                                    <img src={statuscancelledIcon} alt="statuscancelledIcon" />
                                                </div>

                                                <div className="status-timeline-content custom-block1">
                                                    <h6>Order Cancelled</h6>
                                                    <p className='mb-0'>{moment(enquiryResponse?.cancel_at).format("MMMM Do, YYYY, h:mm A")}</p>
                                                </div>
                                            </div>}

                                            {enquiryResponse?.pickup_confirm_date && enquiryResponse?.pickup_confirm_time && <div className="status-timeline-block">
                                                <div className="status-timeline-img status-picture">
                                                    <img src={statusSuccessIcon} alt="SuccessIcon" />
                                                </div>

                                                <div className="status-timeline-content custom-block1">
                                                    <h6>Pickup Confirm</h6>
                                                    <p className='mb-0'>{moment(enquiryResponse?.pickup_confirm_date).format("MMMM Do, YYYY")}, {enquiryResponse?.pickup_confirm_time}</p>
                                                </div>
                                            </div>}
                                            {enquiryResponse?.cancel_by_partner > 0 && <div className="status-timeline-block">
                                                <div className="status-timeline-img status-movie">
                                                    <img src={statuscancelledIcon} alt="statuscancelledIcon" />
                                                </div>

                                                <div className="status-timeline-content custom-block1">
                                                    <h6>Order Rejected</h6>
                                                    <p className='mb-0'>{moment(enquiryResponse?.cancel_at).format("MMMM Do, YYYY, h:mm A")}</p>
                                                </div>
                                            </div>}

                                            {enquiryResponse?.Status == 'Completed' && <div className="status-timeline-block">
                                                <div className="status-timeline-img status-picture">
                                                    <img src={statusSuccessIcon} alt="SuccessIcon" />
                                                </div>

                                                <div className="status-timeline-content custom-block1">
                                                    <h6>Order Completed</h6>
                                                    <p className='mb-0'>{moment(enquiryResponse?.final_status_date).format("MMMM Do, YYYY, h:mm A")}</p>
                                                </div>
                                            </div>}

                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </section >
            <section className="about-section section-padding" style={{ paddingTop: "0px" }}>
                <div className="container">
                    <div className='row justify-content-center'>
                        <div className='col-md-10'>
                            <div className="accordion">
                                <div className="accordion-header" onClick={toggleAccordion2}>
                                    <p style={{ marginBottom: 0 }}>See Device report</p>
                                    <div className={`arrow ${isOpen2 ? 'open' : ''}`}></div>
                                </div>
                                {isOpen2 && (
                                    <div className="accordion-content">
                                        <DeviceReport selectedModel={enquiryResponse?.DeviceInfo} />
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </>
    );
};

export default SalesOrdersDetail;
