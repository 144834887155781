import React, { useEffect, useState } from 'react';
import './ordercancelModal.css';
import bin from '../../assets/images/icon/cancel.svg';
import { cancelorderbycustomer } from '../../utils/api';
import toast from 'react-hot-toast';
import Loader from '../loader/loader';



const OrderCancelModal = ({ isCancelOpen, OnCancelClose, ref_no }) => {
    const [loading, setLoading] = useState(false);
    const handleCancelClose = () => {
        OnCancelClose();
    };

    const handleCancelOrder = async () => {
        try {
            setLoading(true);
            const response = await cancelorderbycustomer(ref_no);
            if (response.success) {
                toast.success(response?.message);
                OnCancelClose();
            } else {
                toast.error(response?.message);
            }
            setLoading(false);
        } catch (error) {
            toast.error(error?.message);
            setLoading(false);
        }
    };



    return (
        <>
{loading && <Loader />}
        <div className={`modal ${isCancelOpen ? 'is-active' : ''}`}>
            <div className="modal-background"></div>

            <div className="modal-content " style={{ width: "40%" }}>
                <button className="modal-close is-large" aria-label="close" onClick={handleCancelClose}>
                    <i className="bi bi-x-circle AddresClose-icon"></i>

                </button>

                <div className='row'>
                    <div className='col-md-12 col-sm-12 ' style={{ padding: "60px" }}>
                        <div className='text-center'>
                            <h4> Are you sure you want to cancel the order ? </h4>
                        </div>
                        <div className='row text-center mt-5'>
                            <div className='col-md-6'>
                                <button className="btn  delete-btn custom-border-btn smoothscroll"  onClick={() => { handleCancelOrder(); }}>Yes, Cancel</button>

                            </div>
                            <div className='col-md-6'>
                                <button className="btn  cancel-btn custom-border-btn smoothscroll" onClick={handleCancelClose}>Ignore </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        </>
        
    );
};

export default OrderCancelModal;
