import { useState } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './index.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home/Home';
import SellMobile from './pages/sell-mobile/SellMobile';
import BuyMobile from './pages/buy-mobile/BuyMobile';
import PurchaseOrders from './pages/purchase-orders/PurchaseOrders';


import RepairMobile from './pages/repair-mobile/RepairMobile';
import Contact from './pages/contact/Contact';
import Layout from './components/layout/Layout';
import NoPage from './pages/no-page/NoPage';
import { HelmetProvider } from 'react-helmet-async';
import Model from './pages/models/Model';
import ModelDetail from './pages/model-details/ModelDetail';
import Option from './pages/options/Option';
import SalesOrders from './pages/sales-orders/SalesOrders';
import SalesOrdersDetail from './pages/sales-order-detail/SalesOrderDetail';
import Exactvalue from './pages/exactvalue/Exactvalue';
import Sellnow from './pages/sell-now/Sellnow';
import EditProfile from './pages/edit-profile/EditProfile';
import Response from './pages/response/response';
import SearchResult from './pages/search-result/SearchResult';
import About from './pages/about/About';
import Faq from './pages/faq/Faq';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import DeviceReports from './pages/device-report/DeviceReport';
import { useUser } from "./UserContext";
import Requote from './pages/requote/Requote';
import Reexactvalue from './pages/reexactvalue/Reexactvalue';
import RouteListener from './RouteListener';
function App() {
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userData')));

  const { user, logoutUser } = useUser();
  return (
    <>
      <HelmetProvider>
        <RouteListener />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/sell-mobile" element={<SellMobile />} />
            <Route path="/sell-mobile/:slug" element={<Model />} />
            <Route path="/sell-mobile/:brand/:model" element={<ModelDetail />} />
            <Route path="/buy-mobile" element={<BuyMobile />} />
            <Route path="/purchase-orders" element={<PurchaseOrders />} />
            <Route path="/repair-mobile" element={<RepairMobile />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<Home />} />
            <Route path="/options/:model/:variant" element={<Option />} />
            <Route path="/search-result/:search" element={<SearchResult />} />
            <Route path="/about" element={<About />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/device-report/:ref_no" element={<DeviceReports />} />
            <Route path="/requote/:ref_no/:model/:variant" element={<Requote />} />
            <Route path="/reexact-value/:ref_no" element={<Reexactvalue />} />
            {
              user && <>
                <Route path="/editprofile" element={<EditProfile />} />
                <Route path="/response/:ref_no" element={<Response />} />
                <Route path="/sales-orders" element={<SalesOrders />} />
                <Route path="/sales-order-detail/:ref_no" element={<SalesOrdersDetail />} />
                <Route path="/exact-value" element={<Exactvalue />} />
                <Route path="/sell-now" element={<Sellnow />} />
              </>
            }



          </Route>
        </Routes>
      </HelmetProvider>
    </>
  )
}

export default App