import { useEffect, useState, useRef } from 'react';
import Slide1 from '../../assets/images/slider/s1.jpg';
import Slide2 from '../../assets/images/slider/s2.jpg';
import Slide3 from '../../assets/images/slider/s3.jpg';
import './Slider.css'; // Create a separate CSS file for styling

const Slider = () => {
    const [isClient, setIsClient] = useState(false);
    const carouselRef = useRef(null);

    useEffect(() => {
        setIsClient(true);
    }, []);

    if (!isClient) {
        return null; // Render nothing on the server-side
    }

    return (
        <div ref={carouselRef} id="carouselExample" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src={Slide1} className="d-block w-100" alt="First slide" />
                </div>
                <div className="carousel-item">
                    <img src={Slide2} className="d-block w-100" alt="Second slide" />
                </div>
                <div className="carousel-item">
                    <img src={Slide3} className="d-block w-100" alt="Third slide" />
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
};

export default Slider;
