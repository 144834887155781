import React from 'react'

const Faq = () => {
    return (
        <>
            <section className="about-section section-padding" id="section_2">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-8 col-12 mx-auto">
                            <div className="pb-5 mb-5">
                                <div className="section-title-wrap mb-4">
                                    <h4 className="section-title">   FAQs
                                    </h4>
                                </div>

                                <h6>Frequently Asked Questions (FAQs) for Cellcash.in
                                </h6>
                                <p><b>1. How does Cellcash.in determine the value of my mobile device?
                                </b></p>
                                <p>We evaluate your mobile device based on several factors including the brand, model, variant, age, functional condition, and physical appearance. You'll be asked to fill out a detailed questionnaire on our website which helps us determine the exact condition of your device and offer you a fair market price.
                                </p>
                                <p><b>2. What types of mobile devices does Cellcash.in accept?
                                </b></p>
                                <p>We accept a wide range of mobile devices from various brands and models. You can enter your device’s details on our website to check if we currently accept your model.
                                </p>
                                <p><b>3. Is my personal information safe with Cellcash.in?
                                </b></p>
                                <p>Yes, protecting your personal information is our top priority. We implement reasonable security measures to protect your data from unauthorized access, use, or disclosure. Please refer to our Privacy Policy for more detailed information on how we protect and use your data.
                                </p>
                                <p><b>4. How do I schedule a pickup for my device?
                                </b></p>
                                <p>Once you receive a valuation for your mobile device and accept the offer, you can schedule a pickup by entering your address and choosing a convenient date and time through our website. A Cellcash.in representative will visit your location to verify the device’s condition and finalize the transaction.
                                </p>
                                <p><b>5. Can I cancel my request after scheduling a pickup?
                                </b></p>
                                <p>Yes, you can cancel your pickup request at any time before the scheduled pickup time. Please contact our customer support to cancel or reschedule.
                                </p>
                                <p><b>6. How and when will I get paid for my device?
                                </b></p>
                                <p>After our representative confirms the condition of your mobile device at your location, payment will be processed immediately via your preferred method—bank transfer, mobile wallet, or cheque. The payment method can be selected during the pickup scheduling process.
                                </p>
                                <p><b>7. What happens if the actual condition of my device differs from what I reported?</b></p>
                                <p>If the actual condition of your device differs from your initial report, the price offer may be adjusted accordingly. Our representative will provide a new valuation based on the actual condition of the device during the pickup. You can choose to accept the revised offer or decline to sell your device.
                                </p>
                                <p><b>8. Do I need to provide any accessories with my mobile device?
                                </b></p>
                                <p>No, it is not necessary to provide any accessories with your mobile device unless specifically mentioned in the valuation offer. However, including accessories such as the original charger and box may increase the valuation of your device.

                                </p>
                                <p><b>9. What should I do before handing over my mobile device?
                                </b></p>
                                <p>We recommend that you back up all your personal data and perform a factory reset on your device to erase personal information. Remove any SIM cards and memory cards before the pickup.
                                </p>
                                <p><b>10. How can I contact Cellcash.in for more assistance?
                                </b></p>
                                <p>If you need further assistance, you can reach us via email at info@cellcash.net or call our customer support line provided on our website. Our team is here to help with any questions or concerns you may have.</p>
                            </div>
                        </div>



                    </div>
                </div>
            </section>

        </>

    )
}

export default Faq;