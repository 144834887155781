import axios from 'axios';
import { liveAPIPath, localAPIPath } from './constants';

// Create an instance of Axios with custom configurations if needed
const api = axios.create({
    baseURL: liveAPIPath, // Set your base URL
    headers: {
        'Content-Type': 'application/json',
    },
});


// Define your API call functions
export const fetchBrands = async () => {
    try {
        const response = await api.get('api/brands');
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const fetchSeriesAndModel = async (slug) => {
    try {
        const response = await api.get(`api/model/${slug}`);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const fetchMobileVariants = async (model) => {
    try {
        const response = await api.get(`api/variant/${model}`);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const fetchSelectedMobileVariant = async (slug, variant) => {
    try {
        const response = await api.get(`api/selectedvariant/${slug}/${variant}`);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const getExactValue = async (obj) => {
    try {
        const response = await api.post('api/getExactValue', obj);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const fetchExistsCustomer = async (mobile) => {
    try {
        const response = await api.get(`api/checkcustomerexists/${mobile}`);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const customerLogin = async (mobile, password) => {
    try {
        const response = await api.post('api/customerlogin', { mobile, password });
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const customerRegistration = async (mobile, name, password, platform) => {
    try {
        const response = await api.post('api/registercustomer', { mobile, password, name, platform });
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const updatecustomerprofile = async (obj) => {
    try {
        const response = await api.post('api/updatecustomerprofile', obj);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};


export const getcustomerprofile = async (customer_id) => {
    try {
        const response = await api.get(`api/getcustomerprofile/${customer_id}`);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const customerOtpVerify = async (mobile, otp) => {
    try {
        const response = await api.post('api/verifycustomerotp', { mobile, otp });
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const forgetpasswordverifycustomerotp = async (mobile, otp) => {
    try {
        const response = await api.post('api/forgetpasswordverifycustomerotp', { mobile, otp });
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const sendForgetPasswordOTPRequest = async (mobileNo) => {
    try {
        const response = await api.get(`api/forgetpasswordotp/${mobileNo}`);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const resendotpOTPRequest = async (mobileNo) => {
    try {
        const response = await api.get(`api/resendotp/${mobileNo}`);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};



export const forgetpasswordcustomer = async (newPassword, customer_id, platform) => {
    try {
        const response = await api.post('api/forgetpasswordcustomer', { newPassword, customer_id, platform });
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const getcustomeraddress = async (customer_id) => {
    try {
        const response = await api.get(`api/getcustomeraddress/${customer_id}`);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const addaddress = async (obj) => {
    try {
        const response = await api.post('api/addaddress', obj);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const editaddress = async (obj) => {
    try {
        const response = await api.post('api/editaddress', obj);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const deleteaddress = async (obj) => {
    try {
        const response = await api.post('api/deleteaddress', obj);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const fetchPincodes = async () => {
    try {
        const response = await api.get('api/pincodes');
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};


export const generatesellnoworder = async (obj) => {
    try {
        const response = await api.post('api/generatesellnoworder', obj);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};


export const getsellnowoder = async (ref_no) => {
    try {
        const response = await api.get(`api/getsellnowoder/${ref_no}`);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};


export const getsalesorders = async (customer_id) => {
    try {
        const response = await api.get(`api/getsalesorders/${customer_id}`);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const cancelorderbycustomer = async (ref_no) => {
    try {
        const response = await api.get(`api/cancelorderbycustomer/${ref_no}`);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};


export const submitcontactus = async (obj) => {
    try {
        const response = await api.post('api/contactus', obj);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};



export const searchresult = async (search) => {
    try {
        const response = await api.get(`api/searchresult/${search}`);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const getReExactValue = async (obj) => {
    try {
        const response = await api.post('api/getReExactValue', obj);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};


export const checkoutcustomer = async (mobile, name, email, locality, city, state, pincode, platform) => {
    try {
        const response = await api.post('api/checkoutcustomer', { mobile, name, email, locality, city, state, pincode, platform });
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export default api;
