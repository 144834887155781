import React from 'react';
import { Helmet } from 'react-helmet-async';

const BuyMobile = () => {
    return (
        <>

            <section className="Search-section section-padding" id="section_1">
                <div className="container bgsellSearch col-lg-6 col-md-6">
                    <div style={{ padding: " 10px 10px 50px 20px" }}>
                        <div className=''>
                            <div className="row " >

                                <div className="col-lg-12 col-md-12 col-12 mb-4 mb-lg-0">
                                    <div className='mb-4 text-center'>
                                        <h1 className="mt-5 mb-0 mb-4">Coming Soon</h1>
                                        <p>We're working on something awesome! Stay tuned.</p>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </section>


        </>

    )
}

export default BuyMobile;
