import { Outlet } from 'react-router';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { useLocation } from 'react-router-dom';
import metadata from '../../metadata.json';
import { Helmet } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';

export default function Layout() {
    window.scrollTo(0, 0);
    const location = useLocation();
    const searchUrl = location.pathname;
    const currentData = metadata.find(item => 
        searchUrl.startsWith(item.url) && (searchUrl === item.url || searchUrl.startsWith(item.url + "/"))
    );

    return (
        <>
            <Helmet>
                <title>{currentData ? currentData.title : "Cell Cash"}</title>
                <meta
                    name="description"
                    content={currentData ? currentData.description : "Cell Cash description"}
                    data-rh="true"
                />
                <link rel="canonical" href={location.pathname} />
            </Helmet>
            {/* {!location.pathname.includes('device-reports' || 'requote')
                &&
                <Header />
            } */}
            {!location.pathname.includes('device-report') && !location.pathname.includes('requote') && !location.pathname.includes('reexact-value') &&
                <Header />
            }
            <Toaster position="top-right" />
            <Outlet />
            {/* {!location.pathname.includes('device-reports' || 'requote')
                &&
                <Footer />
            } */}
            {!location.pathname.includes('device-report') && !location.pathname.includes('requote') && !location.pathname.includes('reexact-value') &&
                <Footer />
            }

        </>
    );
}
