import React from 'react';
import './loader.css'; // Include the CSS for styling the loader

const Loader = () => {
    return (
        <div className="loader-container">
            <div className="loader"></div>
            <div className="loading-text">Loading...</div>
        </div>
    );
};

export default Loader;
