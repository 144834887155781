import React from 'react'

const About = () => {
    return (
        <>
            <section className="about-section section-padding" id="section_2">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-8 col-12 mx-auto">
                            <div className="pb-5 mb-5">
                                <div className="section-title-wrap mb-4">
                                    <h4 className="section-title">   About us
                                    </h4>
                                </div>

                                <p>cellcash is an e-platform for reselling used electronic gadgets.

                                </p>

                                <p>We believe in offering a complete solution wherein customers can sell old or used gadgets hassle-free.
                                    cellcash enables the customers to sell their old gadgets online and get instant cash along with free home pick up.</p>
                                <p>Fast and trusted deliveries through our network. Just download our mobile application using below link to get started today.

                                </p>

                            </div>
                        </div>



                    </div>
                </div>
            </section>

        </>

    )
}

export default About;