import { NavLink } from "react-router-dom";
import FooterLogo from '../../assets/images/white-logo.png';

function Footer() {
    return (
        <>
            {/* <NavLink href="#" className="float" target="_blank">
                <i className="bi bi-whatsapp my-float"></i>
            </NavLink> */}
            <footer className="site-footer">
                <div className="container">
                    <div className="row align-items-baseline" style={{ paddingBottom: "60px" }}>

                        <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 one">
                            <NavLink className="navbar-brand" href="/">
                                <img src={FooterLogo} className="logo-image img-fluid" alt="  " />
                            </NavLink>
                            <p>
                                Cellcash is an e-platform for reselling used electronic gadgets. Cellcash enables the customers to sell their old gadgets online and get instant cash along with free home pick up.
                            </p>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 two">
                            <h6 className="site-footer-title mb-3">Useful Links</h6>
                            <ul className="site-footer-links">
                                <li className="site-footer-link-item">
                                    <NavLink to="/about" className="site-footer-link">About Us</NavLink>
                                </li>
                                <li className="site-footer-link-item">
                                    <NavLink to="/contact" className="site-footer-link">Contact Us</NavLink>
                                </li>
                                <li className="site-footer-link-item">
                                    <NavLink to="/privacy-policy" className="site-footer-link">Privacy Policy</NavLink>
                                </li>
                                <li className="site-footer-link-item">
                                    <NavLink to="/faq" className="site-footer-link">FAQ</NavLink>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 four">
                            <h6 className="site-footer-title mb-3">Contact</h6>
                            <p>
                                <strong className="d-inline me-2">Email:</strong>
                                <a href="mailto:mailcellcash.in@gmail.com">mailcellcash.in@gmail.com</a>
                            </p>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 five">
                            <h6 className="site-footer-title mb-3">Social</h6>
                            <ul className="social-icon">
                                <li className="social-icon-item">
                                    <a href="https://www.instagram.com/p/Co9NJLRLAyq/" target="_blank" rel="noopener noreferrer">
                                        <i className="social-icon-link bi-instagram"></i>
                                    </a>
                                </li>
                                <li className="social-icon-item">
                                    <a href="https://twitter.com/Cellcash_in/status/1628294775966433281/photo/1" target="_blank" rel="noopener noreferrer">
                                        <i className="social-icon-link bi-twitter"></i>
                                    </a>
                                </li>
                                <li className="social-icon-item">
                                    <a href="https://www.facebook.com/photo?fbid=171846748930124&set=a.134257196022413" target="_blank" rel="noopener noreferrer">
                                        <i className="social-icon-link bi-facebook"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </footer>

        </>
    )
}

export default Footer