import { NavLink, useParams } from 'react-router-dom';
import { fetchSeriesAndModel, searchresult } from '../../utils/api';
import { useEffect, useState } from 'react';
import './search-result.css';
import phone from '../../assets/images/sell-img.png';
import { modelImagePath } from '../../utils/constants';

const SellResult = () => {

    const { search } = useParams();
    const [modelData, setModelData] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await searchresult(search);
                setModelData(response.models);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching sell mobile data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);



    return (
        <>


            <section className="about-section section-padding" id="section_2">

                <div className="container">
                    <div className="col-lg-12 col-12 mt-4">
                        <h4 className="text-center">Your Search For <span className='secondary-color'>{search}</span> returned the following </h4>
                    </div>

                </div>
            </section>
            <section className="about-section section-padding" id="section_2" style={{ paddingTop: "0px" }}>

                <div className="container">

                    <div className='sell-result-Desktop'>
                        <div className="row">
                            <div className='col-md-12'>
                                <div className='row'>
                                    {modelData?.map((item) => (
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-12 mb-5" key={item.ItemCode}>
                                            <div className="team-thumb bg-white border-lg">
                                                <NavLink to={`/sell-mobile/${item.cgc_slug}/${item.ic_slug}`} style={{ width: "100%" }}>
                                                    <img src={modelImagePath + item.ic_slug + '.webp'} alt={item.model}

                                                        className="brand-image img-fluid"
                                                        id={item.image}
                                                    />
                                                    <p className='text-center'><b>{item.ItemName}</b></p>
                                                </NavLink>
                                            </div>
                                        </div>
                                    ))}
                                    {modelData?.length == 0 && <h4>No record found.</h4>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='sell-result-Mobile'>
                        <div className='row'>
                            {modelData?.map((item) => (
                                <div className="col-lg-6 col-md-6 col-6 mb-5 mb-lg-3" key={item.id}>
                                    <div className="team-thumb bg-white border-lg">
                                        <NavLink to={`/sell-mobile/${item.cgc_slug}/${item.ic_slug}`} style={{ width: "100%" }}>
                                            <img src={modelImagePath + item.ic_slug + '.webp'} alt={item.model}

                                                className="brand-image img-fluid"
                                                id={item.image}
                                                style={{ height: "100px" }}
                                            />
                                            <p className='itemfont text-center'><b>{item.ItemName}</b></p>
                                        </NavLink>
                                    </div>
                                </div>
                            ))}
                            {modelData?.length == 0 && <h4>No record found.</h4>}
                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}

export default SellResult;