import React, { useState } from 'react';
import './reexactvalue.css';
const Reexactvalue = ({ title, content }) => {
    return (
        <>
           
        </>
    );
}

export default Reexactvalue;