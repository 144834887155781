// RouteListener.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function RouteListener() {
  const location = useLocation();

  useEffect(() => {
    // Function to handle route changes
    const handleRouteChange = (location) => {
      console.log('Route changed to:', location.pathname);
      const element = document.querySelector('.navbar-collapse.show');
      if (element) {
        document.getElementById('btn_menu').click();
      }

      // Add your code here to handle the route change
      // Example: Fetch data, log analytics, etc.
    };

    // Call handleRouteChange on location change
    handleRouteChange(location);

  }, [location]);

  return null;
}

export default RouteListener;
