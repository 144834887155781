import React from 'react'

const PrivacyPolicy = () => {
    return (
        <>

            <section className="about-section section-padding" id="section_2">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-8 col-12 mx-auto">
                            <div className="pb-5 mb-5">
                                <div className="section-title-wrap mb-4">
                                    <h4 className="section-title">   Privacy Policy
                                    </h4>
                                </div>

                                <h6>Effective Date: [01-JAN-2024]</h6>

                                <p>Welcome to Cellcash.in! We respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and share information from or about you when you use our website.
                                </p>
                                <h6>Information We Collect</h6>
                                <p><b>1. Personal Information</b></p>
                                <p><b>Contact Details:</b>We collect your name, mobile number, and email address (optional) when you decide to sell your mobile device to us.
                                </p>
                                <p><b>Device Information:</b>Details about the mobile device, including brand, model, variant, and condition based on your inputs.
                                </p>
                                <p><b>Location Information:</b>We collect your address to facilitate the pickup of the mobile device.
                                </p>
                                <p><b>2. Automatically Collected Information:</b></p>
                                <p><b>Log and Usage Data:</b>Information related to your use of our website, such as IP address, browser type, and usage details.
                                </p>
                                <h6>How We Use Your Information
                                </h6>
                                <p>We use your information to Provide our services, including evaluating your mobile device and arranging for its pickup.
                                </p>
                                <p>Communicate with you, respond to your inquiries, and send you important information regarding transactions.
                                </p>
                                <p>Enhance and improve our services and develop new ones.</p>
                                <p> Comply with legal obligations and resolve any disputes.
                                </p>
                                <h6>Sharing of Your Information
                                </h6>
                                <p>We may share your information with Service providers who perform services on our behalf, such as logistics and payment processing.
                                </p>
                                <p>Law enforcement or other government agencies if required by law or in a good faith belief that such action is necessary to comply with legal obligations or protect and defend our rights or property.
                                </p>
                                <h6>Data Security
                                </h6>
                                <p>We implement reasonable security practices and procedures as required under Indian law to protect your personal information from unauthorized access, use, or disclosure.
                                </p>
                                <h6> Data Retention
                                </h6>
                                <p>We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
                                </p>
                                <h6>Your Rights
                                </h6>
                                <p>You have certain rights regarding the personal information we hold about you. These may include the right to access, correct, or request deletion of your information. You can exercise these rights by contacting us at the details provided below.
                                </p>
                                <h6>Changes to This Privacy Policy
                                </h6>
                                <p>We may update this Privacy Policy from time to time. The latest version will always be posted on our website. We encourage you to review this Privacy Policy regularly for any changes.
                                </p>
                                <h6>Contact Us
                                </h6>
                                <p>If you have any questions about this Privacy Policy or our privacy practices, please contact us at:
                                </p>
                                <p><b>Cellcash.in Customer Support</b></p>
                                <p>Email: info@cellcash.net
                                </p>
                                <p>Phone: 9490990079</p>
                            </div>
                        </div>



                    </div>
                </div>
            </section>

        </>

    )
}

export default PrivacyPolicy;