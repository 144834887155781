import React, { useEffect, useState } from 'react'
import { fetchBrands } from '../../utils/api';
import { NavLink } from 'react-router-dom';
import './sell-mobile.css';
import sellIcon from '../../assets/images/sell-img.png';
import brandIcon from '../../assets/images/brands/apple.webp'; // Path to your first image file
import { brandImagePath } from '../../utils/constants';
import Loader from '../../components/loader/loader';

const SellMobile = () => {

    const [brandsData, setBrandsData] = useState(null);
    const [loading, setLoading] = useState(true);
    const imagePath = '../../assets/images/brands';

    const [searchQuery, setSearchQuery] = useState('');
    const [filteredBrands, setFilteredBrands] = useState([]);

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchBrands();
                setBrandsData(response);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching sell mobile data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);
    useEffect(() => {
        const filterBrands = () => {
            if (brandsData && brandsData.length > 0) {
                const filteredData = brandsData.filter(item =>
                    item.CategoryGroupName.toLowerCase().includes(searchQuery.toLowerCase())
                );
                setFilteredBrands(filteredData);
            }
        };

        filterBrands();
    }, [brandsData, searchQuery]);
    return (
        <>
            {loading && <Loader />}
            <section className="Search-section section-padding" id="section_1" style={{ paddingTop: "0px" }}>
                <div className="container bgsellSearch">
                    <div style={{ padding: "10px" }}>
                        <div className='sell-result-Desktop'>
                            <div className="row " >

                                <div className="col-lg-6 col-md-6 col-12 mb-4 mb-lg-0">
                                    <div className='mb-5'>
                                        <h5 className="mt-5 mb-0 mb-4"><span className="extraFont1">Exchange Your Smartphone for Quick Cash</span></h5>

                                    </div>

                                    <form action="#" method="get" className="custom-form search-form flex-fill me-0" role="search">
                                        <div className="input-group input-group-lg">
                                            {/* <input name="search" type="search" className="form-control" id="search" placeholder="Search For..."
                                                aria-label="Search" /> */}
                                            <input
                                                name="search"
                                                type="search"
                                                className="form-control"
                                                id="search"
                                                placeholder="Search For..."
                                                aria-label="Search"
                                                value={searchQuery}
                                                onChange={handleSearchInputChange}
                                            />

                                            <button type="submit" className="form-control" id="submit">
                                                <i className="bi-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                    <ul className='mt-4' style={{ display: "flex" }}>
                                        <li className='me-2' style={{ fontWeight: "500" }}>

                                            <span> Simple and Hassle-free</span>
                                        </li>
                                        <li className='me-2' style={{ fontWeight: "500" }}>

                                            <span className='mr-2'> Best Price Estimate - Instantly!</span>

                                        </li>
                                        <li className='me-2' style={{ fontWeight: "500" }}>

                                            <span className='mr-2'> Instant Money & Secure Transaction</span>

                                        </li>
                                    </ul>

                                </div>
                                <div className="col-lg-6 col-md-6 col-12 mb-4 mb-lg-0">
                                    <img src={sellIcon} className="custom-block-image img-fluid"
                                        alt="" style={{ width: "60%", height: "auto", margin: "0 auto" }} />
                                </div>

                            </div>
                        </div>

                        <div className="row sell-result-Mobile mt-5" >
                            <div className="col-lg-12 col-md-12 col-12 mb-4 mt-4 mb-lg-0">
                                <div className='mb-5'>
                                    <h4 className="mt-5 mb-0 mb-4"><span className="extraFont1">Exchange Your Smartphone for Quick Cash</span></h4>

                                </div>


                                <ul className='mt-4'>
                                    <li className='me-2 mt-2' style={{ fontWeight: "500" }}>

                                        <span> Simple and Hassle-free</span>
                                    </li>
                                    <li className='me-2 mt-2' style={{ fontWeight: "500" }}>

                                        <span className='mr-2'> Best Price Estimate - Instantly!</span>

                                    </li>
                                    <li className='me-2 mt-2' style={{ fontWeight: "500" }}>

                                        <span className='mr-2'> Instant Money & Secure Transaction</span>

                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>

                </div>
            </section>

            <section className="about-section section-padding" id="section_2" style={{ paddingTop: "0px" }}>

                <div className="container">
                    <div className="col-lg-12 col-12">
                        <div className="section-title-wrap mb-3">
                            <h4 className="section-title">Our brands</h4>
                        </div>
                    </div>
                    <div className='sell-result-Desktop'>
                        <div className="row">

                            {loading ? (
                                <div className="skeleton-loading">
                                    <div className="skeleton-row"></div>
                                    <div className="skeleton-row"></div>
                                    <div className="skeleton-row"></div>
                                </div>
                            ) : (
                                <>
                                    {filteredBrands.length === 0 ? (
                                        <h4 className='pl-1'>No matching brands found</h4>
                                    ) : (
                                        filteredBrands.map((item, index) => (
                                            <div className="col-lg-2 col-md-6 col-12 mb-5 mb-lg-3" key={item.id}>
                                                <NavLink to={`/sell-mobile/${item.slug}`} style={{ width: '100%' }}>
                                                    <div className="team-thumb bg-white shadow-lg">
                                                        <img src={brandImagePath + item.image} width="106" alt={item.CategoryGroupName} />
                                                    </div>
                                                </NavLink>
                                            </div>
                                        ))
                                    )}
                                </>
                            )}

                        </div>
                    </div>
                    <div className='sell-result-Mobile'>
                        <div className="row">

                            {loading ? (
                                <div className="skeleton-loading">
                                    <div className="skeleton-row"></div>
                                    <div className="skeleton-row"></div>
                                    <div className="skeleton-row"></div>
                                </div>
                            ) : (
                                <>

                                    {filteredBrands.length === 0 ? (
                                        <h4 className='pl-1'>No matching brands found</h4>
                                    ) : (
                                        filteredBrands.map((item, index) => (
                                            <div className="col-lg-4 col-md-4 col-4 mb-5 mb-lg-3" key={item.id}>
                                                <NavLink to={`/sell-mobile/${item.slug}`} style={{ width: '100%' }}>
                                                    <div className="team-thumb bg-white shadow-lg">
                                                        <img src={brandImagePath + item.image} width="106" alt={item.CategoryGroupName} />
                                                    </div>
                                                </NavLink>
                                            </div>
                                        ))
                                    )}
                                </>
                            )}

                        </div>
                    </div>
                </div>
            </section>


        </>

    )
}

export default SellMobile;