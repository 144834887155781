import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { submitcontactus } from '../../utils/api';
import Loader from '../../components/loader/loader';
const Contact = () => {

    const [loading, setLoading] = useState(false);
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        contactFormik.setFieldValue(name, value);
    };

    const contactFormik = useFormik({
        initialValues: {
            name: '', email: '', subject: '', message: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            email: Yup.string().required('Email is required').matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, 'Email is invalid'),
            subject: Yup.string().required('Subject is required'),
            message: Yup.string().max(300, 'Message must be at most 300 characters')
        }),
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            setLoading(true);
            try {
                const response = await submitcontactus(values);
                if (response?.success) {
                    contactFormik.resetForm();
                    contactFormik.setFieldValue("name", '');
                    contactFormik.setFieldValue("email", '');
                    contactFormik.setFieldValue("subject", '');
                    contactFormik.setFieldValue("message", '');
                    toast.success(response?.message);
                } else {
                    toast.error(response?.message);
                }
                setLoading(false);
            } catch (error) {
                console.error('API error:', error);
                setLoading(false);
            }
            setSubmitting(false);
        }
    });

    return (
        <>
            {loading && <Loader />}
            <section className="section-padding" id="section_2">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="section-title-wrap mt-4 mb-5">
                            <h4 className="section-title">Contact us </h4>
                        </div>
                        <div className="col-lg-6 col-12 pe-lg-5">
                            <div className="contact-info">
                                <h3 className="mb-4">Feel Free To Contact Us Anytime</h3>

                                <p className="d-flex border-bottom pb-3 mb-4">
                                    <strong className="d-inline me-4">Phone:</strong>
                                    <span>+91 9490 9900 79</span>
                                </p>

                                <p className="d-flex border-bottom pb-3 mb-4">
                                    <strong className="d-inline me-4">Email:</strong>
                                    <a href="#">info@cellcash.net</a>
                                </p>
                                <p className="d-flex border-bottom pb-3 mb-4">
                                    <strong className="d-inline me-4">Website:</strong>
                                    <a href="#"> www.cellcash.in</a>
                                </p>
                                <p className="d-flex">
                                    <strong className="d-inline me-4">Location:</strong>
                                    <span>Hyderabad, Telangana, India</span>
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-12 mt-5 mt-lg-0">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243646.90509573004!2d78.2432313640975!3d17.412608642560837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99daeaebd2c7%3A0xae93b78392bafbc2!2sHyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1713032131993!5m2!1sen!2sin"
                                width="100%" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>

                    </div>
                </div>
            </section>

            <section className="contact-section section-padding pt-0">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-8 col-12 mx-auto">
                            <div className="section-title-wrap mb-5">
                                <h4 className="section-title">  Get in Touch with Us</h4>
                            </div>

                            <form onSubmit={contactFormik.handleSubmit} autoComplete={'off'} className="custom-form contact-form" role="form">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="form-floating">
                                            <input type="text" name="name" id="name" className="form-control"
                                                placeholder="Name*" required="" onChange={handleInputChange} />

                                            <label htmlFor="floatingInput">Name*</label>
                                            <span className="error-message">
                                                {contactFormik.touched.name && contactFormik.errors.name ? (
                                                    <div className="error">{contactFormik.errors.name}</div>
                                                ) : null}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="form-floating">
                                            <input type="email" name="email" id="email"
                                                className="form-control" placeholder="Email*" required="" onChange={handleInputChange} />

                                            <label htmlFor="floatingInput">Email*</label>
                                            <span className="error-message">
                                                {contactFormik.touched.email && contactFormik.errors.email ? (
                                                    <div className="error">{contactFormik.errors.email}</div>
                                                ) : null}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-12">
                                        <div className="form-floating">
                                            <input type="text" name="subject" id="subject" className="form-control"
                                                placeholder="Subject*" required="" onChange={handleInputChange} />

                                            <label htmlFor="floatingInput">Subject*</label>
                                            <span className="error-message">
                                                {contactFormik.touched.subject && contactFormik.errors.subject ? (
                                                    <div className="error">{contactFormik.errors.subject}</div>
                                                ) : null}
                                            </span>
                                        </div>

                                        <div className="form-floating">
                                            <textarea className="form-control" id="message" name="message"
                                                placeholder="Message" onChange={handleInputChange}></textarea>

                                            <label htmlFor="floatingTextarea"> Message </label>
                                            <span className="error-message">
                                                {contactFormik.touched.message && contactFormik.errors.message ? (
                                                    <div className="error">{contactFormik.errors.message}</div>
                                                ) : null}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-12 ms-auto">
                                        <button type="submit" className="form-control">Submit</button>
                                    </div>

                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </section>

        </>

    )
}

export default Contact;