import { NavLink } from "react-router-dom"

function TopHeader() {
    return (
        <>
            <div className="container-fluid top-header-Desktop">
                <div className="row top-header py-2">
                    <div className="col-md-6">
                        <ul className="contact-info">
                            {/* <li><i className="bi bi-phone"></i> <a href="tel:9490990079" className="contactDetail">Phone: 9490990079</a></li> */}
                            <li><i className="bi bi-envelope"></i> <a href="mailto:mailcellcash.in@gmail.com" className="contactDetail">mailcellcash.in@gmail.com</a> </li>
                        </ul>
                    </div>
                    <div className="col-md-6 text-end">
                        <div className='row'>
                            <div className="col-md-7 text-end">
                                <ul className="social-icons">
                                    <li><NavLink href="#"><i className="bi bi-facebook"></i></NavLink></li>
                                    <li><NavLink href="#"><i className="bi bi-twitter"></i></NavLink></li>
                                    <li><NavLink href="#"><i className="bi bi-instagram"></i></NavLink></li>
                                </ul>
                            </div>

                            <div className="col-md-5 text-end">

                                <button className="btn custom-btn2 "><i className="bi bi-geo-alt-fill me-1"></i> Hyderabad <i className="bi bi-chevron-down me-1"></i></button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default TopHeader