import React, { createContext, useState, useContext, useEffect } from 'react';

// Create UserContext
const UserContext = createContext();

// UserContext provider
const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(localStorage.getItem('token') || null);
    const [selectedModel, setSelectedModel] = useState(null);
    useEffect(() => {
        userInfo();
    }, []);

    const loginUser = (userData, authToken) => {
        localStorage.setItem('token', authToken);
        localStorage.setItem('userData', JSON.stringify(userData));
        setUser(userData);
        setToken(authToken);
    };

    const userInfo = () => {
        const storedUserData = JSON.parse(localStorage.getItem('userData'));
        setUser(storedUserData);
    };

    const logoutUser = () => {
        localStorage.setItem('token', null);
        localStorage.setItem('userData', null);
        setUser(null);
    };

    const setSelectedModelforCheckout = (obj) => {
        localStorage.setItem('selectedModel', JSON.stringify(obj));
        setSelectedModel(obj);
    };

    return (
        <UserContext.Provider value={{ user, token, selectedModel, loginUser, logoutUser, setSelectedModelforCheckout }}>
            {children}
        </UserContext.Provider>
    );
};

// Custom hook to use UserContext
const useUser = () => useContext(UserContext);

export { UserProvider, useUser };
