import React, { useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { getsellnowoder } from '../../utils/api';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import './response.css';
import ThankyouIcon from '../../assets/images/icon/check.png';
import FailedIcon from '../../assets/images/icon/error.png';
import Loader from '../../components/loader/loader';

const Response = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { ref_no } = useParams();
    const [response, setResponse] = useState(null);
    const [enquiryResponse, setEnquiryResponse] = useState(null);
    const fetchData = async () => {
        try {
            const response = await getsellnowoder(ref_no);
            setResponse(response?.success);
            if (response?.success) {
                setEnquiryResponse(response?.enquiry);
            } else {
                toast.error(response?.message);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching sell mobile data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);



    return (
        <>
         {loading && <Loader />}
            <section className="about-section section-padding mt-5" id="section_2" style={{ paddingTop: "20px" }}>
                <div className="container">
                    {response && <div className='row justify-content-center mt-5'>
                        <div className="col-md-8">
                            <div className="custom-block1" style={{ marginBottom: "10px", padding: "12px" }} >
                                <div className='row justify-content-center mt-5 mb-5'>
                                    <div className="col-md-12  text-center" >
                                        <img src={ThankyouIcon} />
                                    </div>
                                    <div className="col-md-12 mt-3 text-center" >
                                        <h4>Thank You for Your Order!
                                        </h4>
                                        <p>Your order has been confirmed. Order Number:  <b>#{enquiryResponse?.RefNo}</b></p>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="payment-options text-center mt-2">
                                            <button type="button" className="btn custom-btn1" onClick={() => navigate('/sales-order-detail/' + enquiryResponse.RefNo)}>Order Details</button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>}


                    {!response && <div className='row justify-content-center mt-5'>
                        <div className="col-md-8">

                            <div className="custom-block1" style={{ marginBottom: "10px", padding: "12px" }} >
                                <div className='row justify-content-center mt-5 mb-5'>
                                    <div className="col-md-12  text-center" >
                                        <img src={FailedIcon} />
                                    </div>
                                    <div className="col-md-12 mt-3 text-center" >
                                        <h4>Oops! Something Went Wrong
                                        </h4>
                                        <p>We're sorry, but there was an error processing your order please try again later.

                                        </p>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="payment-options text-center mt-2">
                                            <button type="button" className="btn custom-btn1" onClick={()=>{navigate('/sell-now');}}>Try again</button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>}


                </div>
            </section>
            <section className="about-section section-padding">
                <div className="container">
                </div>
            </section>
        </>
    );
}

export default Response;