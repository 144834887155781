import React, { useEffect, useState } from 'react';
import DeviceReport from '../../components/device-report/DeviceReport';
import './reportModal.css';

const ReportModal = ({ isReportOpen, OnReportClose }) => {
    const [selectedModel, setSelectedModel] = useState(JSON.parse(localStorage.getItem('selectedModel')) || null);

    const handleReportClose = () => {
        OnReportClose();
    };

    // Prevent background scroll when modal is open
    useEffect(() => {
        if (isReportOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isReportOpen]);

    return (
        <div className={`modal ${isReportOpen ? 'is-active' : ''}`}>
            {/* <div className="modal-background"></div> */}

            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">Device Report</p>
                    <button className=" delete" aria-label="close" onClick={handleReportClose} style={{ lineHeight: "normal" }}> <i className="bi bi-x-circle AddresClose-icon"></i></button>
                </header>

                <section className="modal-card-body">
                    <div className="content">
                        <DeviceReport selectedModel={selectedModel} />
                    </div>
                </section>

                <footer className="modal-card-foot">
                    <button className="btn custom-btn recalculate" onClick={handleReportClose}>OK</button>
                </footer>
            </div>
        </div>
    );
};

export default ReportModal;
